/* eslint-disable no-console */
import { useEffect } from 'react';

/**
 * A custom React hook that checks for version mismatch between the client and server.
 * It uses the BroadcastChannel API to communicate between tabs and reloads the page when a version mismatch is detected.
 *
 * @returns {void}
 */
export function useCheckAppVersion(): void {
  useEffect(() => {
    const channel = new BroadcastChannel('app-version');

    channel.onmessage = event => {
      if (event.data.type === 'version-change') {
        console.warn(
          '[APP-VERSION]: Version change detected in another tab. Reloading...'
        );
        window.location.reload();
      }
    };

    const checkVersion = async () => {
      const clientVersion = process.env.NEXT_PUBLIC_APP_VERSION;

      if (!clientVersion) return;

      try {
        const res = await fetch(window.location.href, {
          method: 'HEAD',
          cache: 'no-store',
        });

        const serverVersion = res.headers.get('x-app-version');

        if (serverVersion && serverVersion !== clientVersion) {
          console.warn(`[APP-VERSION]: Detected mismatch. Reloading...`);
          channel.postMessage({ type: 'version-change' });
          window.location.reload();
        }
      } catch (error) {
        console.error('[APP-VERSION]: Failed to check version mismatch', error);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkVersion();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      channel.close();
    };
  }, []);
}
